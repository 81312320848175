// auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthStore } from '../store/auth.store';
import { Router } from '@angular/router';
import { CurrentUserSessionStore } from '../../../core/store/current-user-session.store';

@Injectable()
export class AuthService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authStore: AuthStore,
    private currentUserSessionStore: CurrentUserSessionStore,
    private router: Router
  ) { }

  //login

  login(email: string, password: string) {
    this.authStore.startLogin();
    this.http.post(this.apiUrl + '/auth/login', { email, password }).subscribe({
      next: (response: any) => {
        this.authStore.reset();
        if (response.is_mfa_enabled) {
          this.authStore.setEmail(email);
          this.router.navigate(['auth/login/verify-otp']);
        } else {
          this.currentUserSessionStore.login(
            response.access_token,
            response.refresh_token
          );
          this.router.navigate(['/organization/strategic-profile']);
        }
      },
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Invalid username or password. Please try again.'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  resendOtp() {
    let email = this.authStore.email
    this.http.post(this.apiUrl + '/auth/login/resend-otp', {email}).subscribe({
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Invalid username or password. Please try again.'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  verifyOtp(otp: string) {
    let email = this.authStore.email
    this.http.post(this.apiUrl + '/auth/login/verify-otp', { otp, email }).subscribe({
      next: (response: any) => {
        this.currentUserSessionStore.login(
          response.accessToken,
          response.refreshToken
        );
        this.router.navigate(['']);
      },
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Invalid OTP. Please try again.'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  //Forgot password

  getForgotPasswordOtp(email: string) {
    this.http.post(this.apiUrl + '/auth/password-recovery/send-otp', { email }).subscribe({
      next: () => {
        this.router.navigateByUrl('/auth/password-recovery/verify-otp');
        this.authStore.setLoading(false);
      },
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Invalid username or password. Please try again.'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  resendForgotpasswordOtp() {
    this.http.post(this.apiUrl + '/auth/password-recovery/resend-otp', '').subscribe({
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Please wait before requesting OTP resend again'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  verifyforgotpasswordOtp(otp: string) {
    let email = this.authStore.email
    this.http.post(this.apiUrl + '/auth/password-recovery/verify-otp', { otp, email }).subscribe({
      next: (response: any) => {
        this.currentUserSessionStore.updateAccessToken(response.token)
        this.authStore.setLoading(true);
        this.router.navigateByUrl('/auth/password-recovery/setup-password');
      },
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Invalid OTP. Please try again.'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  //reset password

  resetPassword(password: any, confirm_password: any) {
    let token = this.currentUserSessionStore.accessToken
    this.http.put(this.apiUrl + '/auth/password-recovery/set-password', { password, confirm_password, token }).subscribe({
      next: () => {
        this.router.navigateByUrl('/auth/login');
      },
      error: (err) => {
        if (err.status === 500) {
          this.authStore.setErrorMessage(
            'Internal server error. Please try again later.'
          );
        } else if (err.status === 401 || err.status === 400) {
          this.authStore.setErrorMessage(
            'Invalid username or password. Please try again.'
          );
        } else {
          this.authStore.setErrorMessage('An error occurred. Please try again');
        }
        this.authStore.setLoading(false);
      },
    });
  }

  //logout

  logout() {
    this.authStore.reset();
    this.currentUserSessionStore.logout();
    this.router.navigate(['/auth/login']);
  }
}
