import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  loading: boolean = false;
  errorMessage: string = '';
  email: string = '';
  timing: any;
  displayTimerCount: any = null;
  showResendButton: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  setErrorMessage(error: string) {
    this.errorMessage = error;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  startLogin() {
    this.loading = true;
    this.errorMessage = '';
    this.email = '';
  }

  reset() {
    this.email = '';
    this.loading = false;
    this.errorMessage = '';
  }

  timer(minute: number) {
    this.showResendButton = false;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    clearInterval(this.timing);
    this.timing = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      this.displayTimerCount = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        clearInterval(this.timing)
        this.showResendButton = true
        this.displayTimerCount = null
      }
    }, 1000);
  }
}
