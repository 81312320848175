import { Injectable } from '@angular/core';
import { makeAutoObservable } from 'mobx';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserSessionStore {
  private isLoggedInFlag: boolean = false;
  private readonly prefix: string = 'JethurGRCApp_';
  private readonly accessTokenKey: string = this.prefix + 'accessToken';
  private readonly refreshTokenKey: string = this.prefix + 'refreshToken';
  private readonly languageKey: string = this.prefix + 'languageKey';
  accessToken: string = '';
  refreshToken: string = '';
  language: string = 'en';

  constructor() {
    makeAutoObservable(this);
    this.loadSession();
    this.loadLanguage();
  }

  private getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.error('Error accessing local storage:', e);
      return null;
    }
  }

  private setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error('Error setting local storage:', e);
    }
  }

  private removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing from local storage:', e);
    }
  }

  loadSession() {
    const token = this.getItem(this.accessTokenKey);
    const refresh = this.getItem(this.refreshTokenKey);
    if (token && refresh) {
      this.accessToken = token;
      this.refreshToken = refresh;
      this.isLoggedInFlag = true;
    }
  }

  loadLanguage() {
    const language = this.getItem(this.languageKey);
    if (language) {
      this.language = language;
    }
  }

  setLanguage(language: string) {
    this.language = language;
    this.setItem(this.languageKey, language);
  }

  login(accessToken: string, refreshToken: string) {
    this.isLoggedInFlag = true;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.setItem(this.accessTokenKey, accessToken);
    this.setItem(this.refreshTokenKey, refreshToken);
  }

  updateAccessToken(accessToken: string) {
    this.accessToken = accessToken;
    this.setItem(this.accessTokenKey, accessToken);
  }

  isLoggedIn(): boolean {
    return this.isLoggedInFlag;
  }

  logout() {
    this.isLoggedInFlag = false;
    this.accessToken = '';
    this.refreshToken = '';
    this.removeItem(this.accessTokenKey);
    this.removeItem(this.refreshTokenKey);
  }
}
